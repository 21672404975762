<template>
  <BaseItemForm
    v-slot="{ cancel, itemIsNew }"
    :category="category"
    :item="item"
  >
    <b-row align-v="center">
      <b-col>
        <b-row>
          <b-col>
            <p class="form-control-label">
              <strong v-if="itemIsNew(item)">Ajouter l'article</strong>
              <strong v-else>Modifier l'article</strong>
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-media no-body class="align-items-center">
              <ProductThumbnail :product="product" />
              <b-media-body>
                <p v-if="product.vigieId" class="h6 text-muted mb-0">
                  <span>Code produit: {{ product.vigieId }}</span>
                </p>
                <p
                  v-if="product.isbn13 || product.isbn10"
                  class="h6 text-muted mb-0"
                >
                  <span>ISBN: {{ product.isbn13 || product.isbn10 }}</span>
                </p>
                <p class="h5 mb-0">
                  {{ product.name }}
                </p>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <BaseInput rules="required">
              <BaseRadio v-model="item.required" :name="true">
                Cet article est obligatoire
              </BaseRadio>
              <BaseRadio v-model="item.required" :name="false">
                Cet article est facultatif
              </BaseRadio>
            </BaseInput>
          </b-col>
        </b-row>

        <b-row align-v="end" class="mt-3">
          <b-col cols="6">
            <BaseInput
              v-model="item.neededStudentsQuantity"
              type="number"
              label="Nombre d'étudiant qui devront se procurer l'article"
              rules="required"
            />
          </b-col>
          <b-col cols="6">
            <BaseInput
              v-model="item.nbStudents"
              type="number"
              label="Nombre d'étudiants inscrits au cours"
              rules="required"
            />
          </b-col>
        </b-row>

        <b-row align-v="end" class="mt-3">
          <b-col cols="12">
            <BaseInput
              v-model="item.suggestedQuantity"
              type="number"
              label="Quantité suggérée"
              rules="required"
            />
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <BaseInput class="mb-2">
              <div class="custom-control custom-checkbox">
                <input
                  id="isMandatory"
                  v-model="item.isMandatory"
                  :checked="item.isMandatory"
                  class="custom-control-input"
                  type="checkbox"
                  @input="item.isMandatory = $event.target.checked"
                />
                <label for="isMandatory" class="custom-control-label">
                  Produit obligatoire lors d’un achat via la liste scolaire
                </label>
              </div>
            </BaseInput>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <BaseInput class="mb-2">
              <div class="custom-control custom-checkbox">
                <input
                  id="requiredNextSemester"
                  v-model="item.requiredNextSemester"
                  :checked="item.requiredNextSemester"
                  class="custom-control-input"
                  type="checkbox"
                  @input="item.requiredNextSemester = $event.target.checked"
                />
                <label for="requiredNextSemester" class="custom-control-label">
                  Le produit sera requis à la prochaine session
                </label>
              </div>
            </BaseInput>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <BaseInput
              v-model="item.commentBuyer"
              type="textarea"
              label="Commentaire destiné à l'acheteur de Coop Zone (facultatif)"
            />
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <BaseInput
              v-model="item.commentStudent"
              type="textarea"
              label="Commentaire destiné aux étudiants (facultatif)"
            />
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col>
            <base-button type="default" size="sm" native-type="submit">
              <span class="btn-inner--text">{{
                itemIsNew(item) ? 'Ajouter l\’article' : 'Enregistrer'
              }}</span>
            </base-button>

            <base-button type="secondary" size="sm" @click.prevent="cancel">
              <span class="btn-inner--text">Annuler</span>
            </base-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </BaseItemForm>
</template>
<script>
import { BaseInput, BaseRadio, BaseButton } from '@coop-zone/coop-zone-argon'
import BaseItemForm from '@/components/SchoolLists/Items/BaseItemForm'
import ProductThumbnail from '@/components/Product/ProductThumbnail'

export default {
  components: {
    BaseButton,
    BaseInput,
    BaseItemForm,
    BaseRadio,
    ProductThumbnail,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    itemId: {
      type: String,
      required: true,
    },
  },
  computed: {
    product() {
      return this.item.products[0]
    },
    item() {
      const index = this.category.ListCategoryItems.findIndex(
        (item) => item.id === this.itemId
      )
      return this.category.ListCategoryItems[index]
    },
  },
}
</script>
